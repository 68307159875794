import Navbar from "../components/Navbar/Navbar";
import Link from "next/link";
import Head from 'next/head';

export default function NotFound() {
    return (
        <>
            <Head>
                <title>Not Found | Where is my brand?</title>
                <meta name="description" content="WIMB: Agencia tradicional con ADN Digital" />
                <link rel="icon" href="/favicon.png" />
            </Head>
            <Navbar />
            <div className="d-flex justify-content-center align-items-center min-vh-100">
                <div className="container">
                    <img src="/svg/404.svg" alt="404" className="w-75 d-block mx-auto" />
                    <h4 className="fs-2 fw-bold text-center">Oooops</h4>
                    <p className="text-center">
                        Algo salió mal
                    </p>
                    <Link href={'/'}>
                        <button className="orange-to-black-btn mt-4 d-block mx-auto">
                            IR A INICIO
                        </button>
                    </Link>
                </div>
            </div>
        </>
    )
}